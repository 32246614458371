import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { updateDecision } from "../../api/api";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const OrderSignModal = (props) => {
  const { open, setOpen, content, setOpenSnackbar, setDisableUpdate } = props;
  const user = localStorage.getItem("user");
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelClick = () => {
    setOpen({ open: false, content: {} });
    setOpenSnackbar({
      open: false,
      title: "",
      message: "",
      severity: "info",
    });
    setIsLoading(false);
  };

  const handleUserDecision = async (id, content) => {
    setIsLoading(true);
    try {
      const data = await updateDecision(id, content);
      if (data?.status) {
        setOpenSnackbar({
          open: true,
          title: "success",
          message: data?.status,
          severity: "success",
        });
        setOpen({ open: false, content: {} });
        setIsLoading(false);
        setDisableUpdate((prevState) => ({
          ...prevState,
          disabled: true,
          description: [...(prevState.description || []), content.description],
        }));
      }
    } catch (error) {
      console.log(error);
      setOpenSnackbar({
        open: true,
        title: "error",
        message: "Something went wrong ,please try after sometime.",
        severity: "error",
      });
      setOpen({ open: false, content: {} });
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          color: "black",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            flexGrow: 1,
          }}
        >
          {content?.description}
        </Typography>
        <IconButton onClick={() => handleCancelClick()}>
          <HighlightOffOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography component="p" sx={{ fontWeight: "bold" }}>
              Resource ID
            </Typography>
            <Typography component="p">
              {content?.resourceId?.split("/")[1]}
            </Typography>
          </Grid>
          {content?.resource?.authoredOn && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Authored On
              </Typography>
              <Typography component="p">
                {new Date(content?.resource?.authoredOn).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }
                )}
              </Typography>
            </Grid>
          )}
          {content?.resource?.created && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Created On
              </Typography>
              <Typography component="p">
                {new Date(content?.resource?.created).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }
                )}
              </Typography>
            </Grid>
          )}
          {content?.resource?.note && (
            <Grid item xs={12}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Note
              </Typography>
              <Typography component="p">
                {content?.resource?.note[0]?.authorString}
              </Typography>
            </Grid>
          )}
          {content?.resource?.code && (
            <Grid item xs={12}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Code
              </Typography>
              <Typography component="p">
                {content?.resource?.code?.coding[0].display}
              </Typography>
            </Grid>
          )}
          {content?.resource?.requester && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Requester
              </Typography>
              <Typography component="p">
                {content?.resource?.requester?.display}
              </Typography>
            </Grid>
          )}
          {content?.resource?.insurance && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Insurance
              </Typography>
              <Typography component="p">
                {content?.resource?.insurance[0]?.reference}
              </Typography>
            </Grid>
          )}
          {content?.resource?.use && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Use
              </Typography>
              <Typography component="p">{content?.resource?.use}</Typography>
            </Grid>
          )}
          {content?.resource?.type && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Type
              </Typography>
              <Typography component="p">
                {content?.resource?.type?.coding[0].display}
              </Typography>
            </Grid>
          )}
          {content?.resource?.insurer && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Insurer
              </Typography>
              <Typography component="p">
                {content?.resource?.insurer?.reference}
              </Typography>
            </Grid>
          )}
          {content?.resource?.outcome && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                outcome
              </Typography>
              <Typography component="p">
                {content?.resource?.outcome}
              </Typography>
            </Grid>
          )}
          {content?.resource?.disposition && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Diposition
              </Typography>
              <Typography component="p">
                {content?.resource?.disposition}
              </Typography>
            </Grid>
          )}
          {content?.resource?.intent && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Intent
              </Typography>
              <Typography component="p">{content?.resource?.intent}</Typography>
            </Grid>
          )}
          {content?.resource?.start && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Date Of Appointment
              </Typography>
              <Typography component="p">{content?.resource?.start}</Typography>
            </Grid>
          )}
          {content?.resource?.status && (
            <Grid item xs={6}>
              <Typography component="p" sx={{ fontWeight: "bold" }}>
                Status
              </Typography>
              <Typography component="p">{content?.resource?.status}</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={handleCancelClick}>
          <Typography>Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleUserDecision(user, content);
          }}
          disabled={isLoading}
        >
          <Typography sx={{ textTransform: "capitalize" }}>
            {content?.type} on EHR
          </Typography>
          {isLoading && (
            <CircularProgress
              sx={{ ml: ".5rem", color: "rgb(6 70 116)" }}
              size={20}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OrderSignModal;
